import React from "react";
import { useTranslation } from 'react-i18next';

import TopBanner from "../top_banner/product_top_banner";
import LockdownFunctions from "../product/lockdown_functions";
import Help from "../help/help";
import SecondaryHeader from "../secondary-header";
import { secondaryMenu } from "../../constants";

const Preparation = () => {
  const { t } = useTranslation();
  const LockdownFunctionData = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/tools_icon_with_bg.svg",
      title: `${t('decision_making_skills')}`,
      description: `${t('ability_of_the_candidate_to_conduct')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/brain_bulb_icon_with_bg.svg",
      title: `${t('solving_complex_problems')}`,
      description: `${t('mastery_of_basic_arithmetic')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/puzzle_icon_with_bg.svg",
      title: `${t('digital_and_spatial_logic')}`,
      description:
      `${t('analytical_capacity_of_the_candidate')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/head_with_setting_icon_inside_with_bg.svg",
      title: `${t('critical_thinking')}`,
      description:
      `${t('ability_of_the_candidate_to_enter')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/man_with_puzzle_in_bg_icon_with_bg.svg",
      title: `${t('editorial_capacity')}`,
      description:
      `${t('ability_of_the_candidate_to_distinguish')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scope_with_alert_icon_with_bg.svg",
      title: `${t('contemporary_issues')}`,
      description:
        `${t('knowledge_of_the_candidate_on_the_major')}`,
    }
  ];

  return (
    <>
      <div className={`conatiner font-sans`}>
        <SecondaryHeader
          menu={secondaryMenu}
        />
        <TopBanner
          pageTitle={t('evaluation')}
          title={t('online_test_preparation')}
          description={t('practice_with_mock_exam')}
          imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/woman-squatting.svg"
          page={"platform"}
          leftButton={{
            text: `${t("free_trials")}`,
            href: "/free-trial",
          }}
          rightButton={{
            text: `Demo >`,
            href: "/demo",
          }}
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-5/6 my-32 relative`}>
          <img
            alt="right star "
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
            className="hidden  md:block absolute -left-20 -top-20"
          />
          <LockdownFunctions
            title={t('all_six_subtests_are_covered')}
            subTitle={t('with_mereos_choose_the_skills')}
            Data={LockdownFunctionData}
          />

          <div className={`mx-auto my-20`}>
            <Help
              title={t('29_without_subscription')}
              description={t('2_mock_tests_access')}
              button={{
                text: "Visit Help Center",
                href: ""
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preparation;
