import * as React from "react";

import Layout from "../../../components/layout/layout";
import Seo from "../../../components/seo";
import Preparation from "../../../components/tasem-test/preparation";

const Index = () => {
  return (
    <>
      <Seo
        title="Préparation au TASEM"
        description="Une suite d’outils assurant une préparation optimale au test TASEM : simulation des conditions
        d'examen avec des tests officiels exclusifs, fiche de cours sur tous les types de questions et suivi de la
        performance du candidat."
      />
      <Layout>
        <Preparation />  
      </Layout>
    </>
  )
}

export default Index;
