import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const LockdownFunctions = ({ title, subTitle, Data }) => {
  const [CardsData, setCardsData] = useState([...Data]);
  const {t} = useTranslation();
  return (
    <>
      <div className="w-full text-center mx-auto my-10 space-y-4">
        <div className="font-semibold text-3xl md:text-5xl">
          {t(title)}
        </div>
        <div className="w-full text-center font-normal md:font-medium mx-auto text-gray-500 text-sm md:text-xl">
          {t(subTitle)}
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row flex-wrap mx-auto items-center justify-between ">
        {
          CardsData.map((card) => (
            <div
              className="w-full md:w-31 flex flex-col p-6 space-y-2 border-gray-200 pb-0 rounded-xl my-3 hover:shadow-lg"
              style={{ minHeight: "305px", borderWidth: '1px' }}
            >
              <div>
                <img
                  alt="home page"
                  src={card.iconSrc}
                  className="m-0 p-0 h-12"
                />
              </div>
              <div className="text-xl font-semibold">
                {t(card.title)}
              </div>
              <div className="text-sm md:text-base font-normal text-gray-500">
                {t(card.description)}
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
};

export default LockdownFunctions;
